import React from 'react'
import { Link } from 'gatsby'
import Pill from '../components/pill'
import PublicationList from './publicationList'
import styles from '../styles/memberCard.module.css'
import LinkedInIcon from '../assets/linkedIn.svg'
import WebsiteIcon from '../assets/website.svg'

const MemberCard = ({picture, name, expertise, currentExpertise, role, institution, linkedIn, website, slug, publications}) => {
    
    if(publications) {
        publications.forEach(publication => {
            publication.data.Date = new Date(publication.data.Date)
        })
        publications.sort((a, b) => a.data.Date - b.data.Date).reverse()
    }

    return (
        <div className={styles.memberCard}>
            <div className={styles.content}>
                <Link to={`/network/${slug}`} className={styles.member}>
                    <div className={styles.header}>
                        {
                            picture && (<div className={styles.picture} style={{backgroundImage: `url(${picture})`}}/>)
                        }
                    <h2>{name}</h2>
                    </div>
                    <p>{institution}</p>
                    {
                        role.split('\n').map((role, i) => <p key={i} className={styles.role}>{role}</p>)
                    }
                </Link>
                <div className={styles.expertise}>
                    {
                        expertise?.sort((a, b) => a.localeCompare(b)).map((expertise, i) => (
                            <Pill key={i} link={`/network?expertise=${expertise}`} label={expertise} disabled={expertise === currentExpertise}/>
                        ))
                    }
                </div>
                <div className={styles.links}>
                    {
                        linkedIn && (
                            <a href={linkedIn} target='_blank' rel='noreferrer'>
                                <LinkedInIcon/>
                                <span>LinkedIn</span>
                            </a>
                        )
                    }
                    {
                        website && (
                            <a href={website} target='_blank' rel='noreferrer'>
                                <WebsiteIcon/>
                                <span>Website</span>
                            </a>
                        )
                    }
                </div>
                <PublicationList publications={publications} max={3} readMore={`/network/${slug}`}/>
            </div>
        </div>
    )
}

export default MemberCard