import React, { useState, useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { graphql } from 'gatsby'
import Filters from '../components/filters'
import Layout from '../components/layout'
import PromptButton from '../components/promptButton'
import Message from '../components/message'
import MemberCard from '../components/memberCard'
import styles from '../styles/network.module.css'
import IconNetwork from '../assets/icon-network.svg'

const Network = ({ data }) => {

    const {allAirtableNetwork:network} = data
    const [currentTag] = useQueryParam('tag', StringParam)
    const [scroll, setScroll] = useState(0)
    const [search, setSearch] = useState('')
    const tags = [...new Set(network.nodes.map(resource => resource.data.Expertise ?? []).flat())].sort()

    const filteredNetwork = network.nodes
        .filter(member => currentTag === undefined || member.data.Expertise?.includes(currentTag))
        .filter(member => search === '' || member.data.Display_name.toLowerCase().includes(search.toLocaleLowerCase()) || (member.data.Institution?.[0].data.Name ?? '').toLowerCase().includes(search.toLocaleLowerCase()))

    const handleMaterialRequest = () => {
        window.open('https://airtable.com/shrH7bDGbgCB2aAq8', '_blank')
    }

    useEffect(() => {
        window.scrollTo(0, scroll)
	})

    return (
		<Layout>
            <div className={styles.header}>
                <h1>Network</h1>
                <PromptButton primary={true} handleClick={handleMaterialRequest}>Contact experts</PromptButton>
            </div>
            <Filters path={`/network`} tags={tags} currentTag={currentTag} search={search} handleClick={() => setScroll(window.scrollY)} handleSearch={value => setSearch(value)}/>
            {
                filteredNetwork.length > 0? (
                    <div className={styles.network}>
                        {
                            filteredNetwork.map((member, i) => {
                                const {data} = member
                                return (
                                    <MemberCard key={data.Slug} name={data.Display_name} picture={data.Picture?.[0].url} institution={data.Institution?.[0].data.Name} role={data.Role} linkedIn={data.LinkedIn} website={data.Website} expertise={data.Expertise} currentTag={currentTag} publications={data.Publications ?? undefined} slug={data.Slug}/>
                                )
                            })
                        }
                    </div>
                ) : (
                    <Message Icon={IconNetwork} content={'Network members not found'}/>
                )
            }
		</Layout>
	)
}

export const query = graphql`
    query {
		allAirtableNetwork(sort: {order: ASC, fields: data___Display_name}) {
            nodes {
                data {
                    Picture {
                        url
                    }
                    Publications {
                        data {
                            Title
                            Source
                            Source_type
                            Author
                            Date
                            HideFromNetwork
                        }
                    }
                    Display_name
                    Expertise
                    LinkedIn
                    Website
                    Institution {
                        data {
                            Name
                        }
                    }
                    Role
                    Slug
                }
            }
        }
    }
`

export default Network
